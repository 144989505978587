export const HOME = '/';
export const LOGIN = '/login';
export const SERVICE = '/service';
export const ABOUT = '/about';
export const CONTACT = '/contact';
export const DOCUMENTS = '/documents';
export const RESOURCES = '/resources';
export const ADMIN = '/admin';
//export const TEXTBOOKS = '/textbooks';
export const PROFEXP = '/professional-experiences';
export const PROFILE = '/profile';
export const SERVICEAPPROVE = '/approval-service';
export const PROFEXPAPPROVE = '/approval-professional';
export const REUNION = '/reunion2025';
export const BINGO = '/bingo';