import React, {
    ReactElement,
    useEffect,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import { useAccount } from 'account';
import { AccountPermissions } from 'account/view_model/account.viewmodel';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

// add navagation to login page if they are not already logged in

export default function BingoLandingPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { account, refreshAccount } = useAccount();

    useEffect(() => {
        window.location.href = "https://georgecrochiere.github.io/comm329-finalproject-bingoselector/"; // Bingo page
        // Ice Cream List - In client/public/files - URL - clarksonhonors.org/files/ice_cream_list.txt
    }, []);

    return (
        <p style={{ margin: '20px' }}>Please wait. Attempting redirect..</p>
    );
}