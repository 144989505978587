const steeringBoardModel = {
  members: [
    {
      id: 'obrienae',
      name: 'Abbie O\'Brien',
      image: '/images/members/obrienae.jpg',
      email: 'obrienae@clarkson.edu',
      committee: 'none',
      position: 'Co-Chair',
    },    
    {
      id: 'kraynae',
      name: 'Elizabeth Kraynak',
      image: '/images/members/blank_profile.png',
      email: 'kraynae@clarkson.edu',
      committee: 'none',
      position: 'Co-Chair',
    },
    {
      id: 'mclaugrj',
      name: 'Rosalia McLaughlin',
      image: '/images/members/mclaugrj.jpg',
      email: 'mclaugrj@clarkson.edu',
      committee: 'webmaster',
      position: 'Webmaster',
    },
    {
      id: 'witteja',
      name: 'Joseph Witte',
      image: '/images/members/witteja.jpg',
      email: 'witteja@clarkson.edu',
      committee: 'webmaster',
      position: 'Webmaster',
    },
    {
      id: 'faziona',
      name: 'Nicolena Fazio',
      image: '/images/members/faziona.JPG',
      email: 'faziona@clarkson.edu',
      committee: 'none',
      position: 'Ambassador',
    },
    {
      id: 'clarnemp',
      name: 'Matthew Clarner',
      image: '/images/members/blank_profile.png',
      email: 'clarnemp@clarkson.edu',
      committee: 'none',
      position: 'Ambassador',
    },
    {
      id: 'boukhamb',
      name: 'Michele Bou Khalil',
      image: '/images/members/blank_profile.png',
      email: 'boukhamb@clarkson.edu',
      committee: 'none',
      position: 'DEI Chair',
    },
    {
      id: 'abelcr',
      name: 'Callan Abel',
      image: '/images/members/blank_profile.png',
      email: 'abelcr@clarkson.edu',
      committee: 'none',
      position: 'Integration Chair',
    },
    {
      id: 'hanzalam',
      name: 'Aiden Hanzalik',
      image: '/images/members/hanzalam.jpg',
      email: 'hanzalam@clarkson.edu',
      committee: 'none',
      position: 'Service Chair',
    },
    {
      id: 'jansenap',
      name: 'Adam Jansen',
      image: '/images/members/blank_profile.png',
      email: 'jansenap@clarkson.edu',
      committee: 'none',
      position: '2026 Service Rep',
    },
    {
      id: 'stewarpl',
      name: 'Palmer Steward',
      image: '/images/members/blank_profile.png',
      email: 'stewarpl@clarkson.edu',
      committee: 'none',
      position: '2026 Service Rep',
    },

    {
      id: 'silerns',
      name: 'Nicholas Siler',
      image: '/images/members/blank_profile.png',
      email: 'silerns@clarkson.edu',
      committee: 'none',
      position: '2027 Service Rep',
    },
    {
      id: 'koranca',
      name: 'Catherine Koran',
      image: '/images/members/koranca.png',
      email: 'koranca@clarkson.edu',
      committee: 'none',
      position: '2027 Service Rep',
    },
    {
      id: 'cauleyap',
      name: 'Tonko Cauley',
      image: '/images/members/blank_profile.png',
      email: 'cauleyap@clarkson.edu',
      committee: 'none',
      position: '2028 Service Rep',
    },
    {
      id: 'kuklasj',
      name: 'Sigmond Kukla',
      image: '/images/members/blank_profile.png',
      email: 'kuklasj@clarkson.edu',
      committee: 'none',
      position: '2028 Service Rep',
    },
    {
      id: 'borstjr',
      name: 'Joseph Borst',
      image: '/images/members/blank_profile.png',
      email: 'borstjr@clarkson.edu',
      committee: 'none',
      position: 'Activities Chair',
    },
    {
      id: 'crochigd',
      name: 'George Crochiere',
      image: '/images/members/crochigd.jpg',
      email: 'crochigd@clarkson.edu',
      committee: 'none',
      position: '2025 Social Chair',
    },
    {
      id: 'coreyem',
      name: 'Evan Corey',
      image: '/images/members/blank_profile.png',
      email: 'coreyem@clarkson.edu',
      committee: 'none',
      position: '2027 Social Chair',
    },
    {
      id: 'weilanks',
      name: 'Kalib Weiland',
      image: '/images/members/blank_profile.png',
      email: 'weilanks@clarkson.edu',
      committee: 'none',
      position: '2028 Social Chair',
    },
    {
      id: 'brancamc',
      name: 'Melissa Brancato',
      image: '/images/members/blank_profile.png',
      email: 'brancamc@clarkson.edu',
      committee: 'none',
      position: '2028 Social Chair',
    },
    {
      id: 'nasellts',
      name: 'Trey Naselli',
      image: '/images/members/blank_profile.png',
      email: 'nasellts@clarkson.edu',
      committee: 'none',
      position: 'Mentorship',
    },
    {
      id: 'crjperez',
      name: 'Cristian Perez',
      image: '/images/members/blank_profile.png',
      email: 'crjperez@clarkson.edu',
      committee: 'none',
      position: 'Mentorship',
    },
    {
      id: 'readtl',
      name: 'Timothy Read',
      image: '/images/members/readtl.jpg',
      email: 'readtl@clarkson.edu',
      committee: 'none',
      position: 'Designer',
    },
    {
      id: 'custerl',
      name: 'Lydia Custer',
      image: '/images/members/custerl.jpeg',
      email: 'custerl@clarkson.edu',
      committee: 'none',
      position: 'Historian',
    },
    // {
    //   id: 'wolfmm',
    //   name: 'Miranda Wolf',
    //   image: '/images/members/blank_profile.png',
    //   email: 'wolfmm@clarkson.edu',
    //   committee: 'none',
    //   position: 'Graduate Mentor',
    // },
    {
      id: 'companmr',
      name: 'Miles Compani',
      image: '/images/members/blank_profile.png',
      email: 'companmr@clarkson.edu',
      committee: 'none',
      position: 'Graduate Mentor',
    },
  ],
  committees: [
    {
      id: 'activity',
      label: 'Activities Committee',
      summary: [
        {
          type: 'paragraph',
          text:
            `The Activities Committee organizes events each semester to 
            encourage relations between honors classes and the Clarkson 
            community.`,
        },
        {
          type: 'paragraph',
          text:
            `The co-chairs of the Activities Committee are elected by the entire
            honors student body, however, students may volunteer for ad hoc
            committees for specific events. The Activities Committee is 
            responsible for working with Honors Program staff to organize the 
            following annual events and more:`,
        },
        {
          type: 'list',
          text: [
            'Ben & Jerry\'s Nights',
            'Fondue Night',
            'December Solstice Party',
            'End-of-year Picnic',
          ],
        },
      ],
    },
    {
      id: 'service',
      label: 'Service Committee',
      summary: [
        {
          type: 'paragraph',
          text:
            `The members of the Service Committee help all Honors Program 
            students complete the service requirement for graduating with 
            honors, by coordinating service events and tracking hours. Each 
            class is represented by one member of their class on the Service 
            Committee; the co-chairs are elected by the entire honors student 
            body. There responsibilities include:`,
        },
        {
          type: 'list',
          text: [
            'introduce students to the service requirement during orientation',
            'inform students of service opportunities',
            `organize service events that provide at least 500 person-hours of
            service per semester`,
            `maintain a record of participation in service for each member of
            the Honors Program`,
            'promote a spirit of service in the Honors Program',
            `recognize extraordinary participation in honors service through 
            awards and events`,
          ],
        },
      ],
    },
    {
      id: 'ambassador',
      label: 'Ambassadors Committee',
      summary: [
        {
          type: 'paragraph',
          text:
            `The co-chairs of the Honors Student Ambassadors are elected by the
            honors student body, and the rest of the members volunteer for their
            roles. The Honors Student Ambassadors are the outreach arm of the 
            Honors Program, helping to organize student support for the
            following activities:`,
        },
        {
          type: 'list',
          text: [
            'Honors Program orientation',
            'Open houses and overnights for prospective students',
            'On-campus recruitment',
            'Phone-a-thons',
            'Alumni events',
          ],
        },
      ],
    },
    {
      id: 'historian',
      label: 'Historians Committee',
      summary: [
        {
          type: 'paragraph',
          text:
            `The purpose of the Historian Committee is to maintain a record of
            Honors Program events, including location and attendance, in order 
            to improve upon future events. Additionally, the Historians provide
            associated imagery by photographing events. The committee’s
            responsibilities include:`,
        },
        {
          type: 'list',
          text: [
            'attend and photograph events sponsored by the Honors Program',
            `maintain a record of events and pictures that can be accessed upon
            the request of Honors Program Staff or the Steering Board`,
            `maintain a record of events and pictures that can be accessed upon
            the request of Honors Program Staff or the Steering Board`,
            'upkeep of Steering Board committee descriptions',
            `contribute to the Honors Program Instagram and other social media
            platforms`,
          ],
        },
      ],
    },
    {
      id: 'diversity',
      label: 'Diversity & Incl. Committee',
      summary: [
        {
          type: 'paragraph',
          text:
            `The Diversity & Inclusion committee is responsble from promoting
            diversity, equity, and inclusion within the Honors Society. They
            host and promote programming, trainings, and other events aimed at
            ensuring all students within the honors society feel accepted within
            the program.`,
        },
      ],
    },
    {
      id: 'webmaster',
      label: 'Webmaster Committee',
      summary: [
        {
          type: 'paragraph',
          text:
            `The Webmasters maintain the honors student government website, the
            email distribution lists, and all remaining technological
            infrastructure used by the honors student government. Webmasters
            also assist with any technology-related projects as needed.`,
        },
        {
          type: 'paragraph',
          text:
            `The webmasters can be contacted by emailing 
            webmaster@clarksonhonors.org; this email will be checked and 
            answered when the webmasters meet.`,
        },
      ],
    },
  ],
};

export default steeringBoardModel;
