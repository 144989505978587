import React, {
    ReactElement,
    useEffect,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

import {
    Container,
} from '../resources_page/styles/resources_page.style';
import {
    Innerr,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Heading6,
    SummaryText,
    BreakLine,
    SummaryTextCenter,
    Image,
    ImgCaption,
} from './styles/reunion_pages.style';

export default function ReunionLandingPage() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <PageTemplate active="home" reunion={true}>
            <HeaderCard bkgSrc="/images/headers/Leaves.jpg">
                <HeaderCard.Title>Honors Reunion 2025</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Innerr>
                    <Heading1>Clarkson University Honors Program Alumni Reunion Weekend</Heading1>
                    <BreakLine />
                    <SummaryTextCenter>
                        Join to stay updated and find out more about the updates to the Clarkson University Honors Program!
                    </SummaryTextCenter>
                    <BreakLine />
                    <Heading2>June 20-22, 2025</Heading2>
                    <Heading3>Cost Information:</Heading3>
                    <SummaryText>
                        <ul>
                            <li><b>Price (Finalizing): </b>$40 Dinner with cash bar; $10 Ice Cream event</li>
                            <li>Tickets Include:</li>
                            <ul>
                                <li>Friday Dinner</li>
                                <li>Saturday Breakfast</li>
                                <li>Saturday Ice Cream</li>
                            </ul>
                            <li>Children free (included with tickets)</li>
                        </ul>
                    </SummaryText>
                    <Image src='/images/reunion/Honors Group and Team Building_2010.JPG' />
                    <ImgCaption>Honors Team Building - August 2010</ImgCaption>
                    <BreakLine />
                    <Heading3>Meal Information:</Heading3>
                    <SummaryText>
                        <ul>
                            <li>Dinner:</li>
                            <ul>
                                <li>Grilled BBQ Chicken</li>
                                <li>Hotdogs & Cheeseburgers with condiments</li>
                                <ul>
                                    <li>Lettuce, Tomato, Onion, Cheese, Pickles, Relish, Mayo, Mustard, Ketchup, etc.</li>
                                </ul>
                                <li>Grilled Mixed Vegetables</li>
                                <li>Tossed Salad</li>
                                <li>Potato Salad</li>
                                <li>Macaroni Salad</li>
                                <li>Mixed Fruit</li>
                                <li>David's Cookies (quarter cuts)</li>
                            </ul>
                        </ul>
                    </SummaryText>
                    <Image src='/images/reunion/Honors Code Crackers_2005.jpg' />
                    <ImgCaption>Honors Code Crackers - November 2005</ImgCaption>
                    <BreakLine />
                    <Heading2>More details to come!</Heading2>
                    <BreakLine />
                    <SummaryTextCenter><i>Issues with any photos? Feel free to send an email at webmaster@clarksonhonors.org with specific information and requests.</i></SummaryTextCenter>
                </Innerr>
            </Container>
        </PageTemplate>
    );
}