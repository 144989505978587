import React, {
    ReactElement,
} from 'react';
import {
    Routes,
    Route,
} from 'react-router-dom';
import BingoLandingPage from './landing_page';
import ErrorPage from 'error_page';

export default function BingoPage() {
    return (
        <Routes>
            <Route exact path="/" element={<BingoLandingPage />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    );
}
