import React, {
    useState,
    useEffect,
} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    Modal,
    Box,
    TextField,
    FormControl,
    CircularProgress,
    Select,
    MenuItem,
    InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const { updateService, default: serviceApprovalPageController } = require('../controller/service_approval.controller');

export const TableServiceHoursNewApproval = ({ tableBody }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align='left'>Name</TableCell>
                        <TableCell align='left'>Class</TableCell>
                        <TableCell align='left'>Service Date</TableCell>
                        <TableCell align='left'>Submission Date</TableCell>
                        <TableCell align='left'>Type</TableCell>
                        <TableCell align='left'>Description</TableCell>
                        <TableCell align='left'>Hours<br />Approved / Requested</TableCell>
                        <TableCell align='left'>Status</TableCell>
                        <TableCell align='left'>Update</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBody}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const allBaseTypes = [
    "INVALID",
    "Honors Approved",
    "Clarkson University",
    "St. Lawrence",
    "External",
    "HP 200",
    "Internal Clarkson Service",
    "External Service (at Home)"
];

const typeOptionList = [
    { value: 1, label: "ARCHIVED: Honors Approved" },
    { value: 2, label: "ARCHIVED: Clarkson University" },
    { value: 3, label: "ARCHIVED: St. Lawrence" },
    { value: 4, label: "ARCHIVED: External" },
    { value: 5, label: "HP 200" },
    { value: 6, label: "Internal Clarkson Service" },
    { value: 7, label: "External Service (at Home)" }
];

export const NewHoursRow = ({ serviceID, name, yearGrad, unixSubmit, unixService, type, location, description, minReq, minAppr, supName, supContact, imgPath, reviewerID, reviewerComment, reviewerName, newHoursTable = true, isCoChair = false }) => {

    let status = "";
    let statusColor = "darkorange"
    if ((minAppr != 0) && reviewerID != null) {
        status = "Approved";
        statusColor = "green";
    } else if (minAppr == 0 && reviewerID > 0) {
        status = "Rejected";
        statusColor = "red";
    } else {
        status = "Pending";
    }

    // Account for newlines in text 
    const descGen = (description) => {
        const descParts = description.replaceAll('\r\n', '\n').split('\n')
        const results = [];
        descParts.forEach(line => {
            results.push(<Typography>{line}</Typography>)
        });
        return results;
    }

    return (
        <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell>{yearGrad}</TableCell>
            <TableCell>{new Date(unixService * 1000).toLocaleDateString()}</TableCell>
            <TableCell>{new Date(unixSubmit * 1000).toLocaleDateString()}</TableCell>
            <TableCell>{allBaseTypes[type]}</TableCell>
            <TableCell style={{ maxWidth: "262.133px" }}>{descGen(description)}</TableCell>
            <TableCell>{(minAppr == null) ? 0 : minAppr / 60} / {minReq / 60}</TableCell>
            <TableCell style={{ color: statusColor }}>{status}</TableCell>
            <TableCell><ServiceDetailsModal serviceID={serviceID} name={name} yearGrad={yearGrad} unixSubmit={unixSubmit} unixService={unixService} type={type} location={location} description={description} minReq={minReq} minAppr={minAppr} supName={supName} supContact={supContact} imgPath={imgPath} reviewerID={reviewerID} reviewerComment={reviewerComment} reviewerName={reviewerName} newHoursTable={newHoursTable} isCoChair={isCoChair}></ServiceDetailsModal></TableCell>
        </TableRow>
    );
}

const WHITE = '#FFFFFF';
const CLARKSON_GREEN = '#004F42';
const CLARKSON_YELLOW = '#F3D200';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    bgcolor: WHITE,
    border: '4px solid ' + CLARKSON_GREEN,
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
}

const modalStyleOverlay = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    bgcolor: '#3e3e3e3e',
    border: '4px solid ' + CLARKSON_GREEN,
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
}

const waitCircleStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'none',
}

const imgStyle = {
    width: '100%',
    height: '75%',
}

export const ApproveServiceButton = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 100%;
    background-color: green;
`;
ApproveServiceButton.defaultProps = {
    type: 'submit',
    variant: 'contained',
};

export const UpdateServiceButton = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 100%;
`;

UpdateServiceButton.defaultProps = {
    type: 'submit',
    variant: 'contained',
};

const descGen = (description) => {
    const descParts = description.split('\r\n')
    const results = [];
    descParts.forEach(line => {
        results.push(<Typography>{line}</Typography>)
    });
    return results;
}

export const ServiceDetailsModal = ({ serviceID, name, yearGrad, unixSubmit, unixService, type, location, description, minReq, minAppr, supName, supContact, imgPath, reviewerID, reviewerComment, reviewerName, newHoursTable, isCoChair }) => {
    const [open, setOpen] = useState(false);
    const [openPending, setOpenPending] = useState(false);
    const [approvedHours, setApprovedHours] = useState(0);
    const [comment, setComment] = useState('');
    const [typeSel, setTypeSel] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const openModal = () => {
        setOpen(true);
    }
    const closeModal = () => {
        setOpen(false);
    }

    const openModalOverlay = () => {
        setOpenPending(true);
    }
    const closeModalOverlay = () => {
        setOpenPending(false);
    }

    const getImage = (imgPath) => {
        if (imgPath == null || imgPath.length < 2) {
            return <Typography>&emsp;No Image Present</Typography>;
        }
        // return <img src="https://drive.google.com/thumbnail?id=1e6un5AsK7bdzKpIo1rq0-2hQJ0VsHSVm" style={imgStyle} alt='Image Failed to Render' />;
        // return <img src={"https://drive.usercontent.google.com/download?id=" + imgPath} style={imgStyle} alt='Image Failed to Render'></img>;
        return <iframe src={"https://drive.google.com/file/d/" + imgPath + "/preview"} style={imgStyle} allow="autoplay"></iframe>;
    }

    const defaultMin = (minAppr) => {
        if (reviewerID == null && reviewerName == null) {
            // Nothing set
        } else {
            setApprovedHours(minAppr / 60);
            setComment(reviewerComment);
        }
    }

    const presetApproved = () => {
        setComment('');
        setApprovedHours(minReq / 60);
    }

    const presetRejected = () => {
        setComment('');
        setApprovedHours(0);
    }

    const presetRejectedLate = () => {
        setComment('Rejected: Hours turned in 3 months (1 semester) past service date.');
        setApprovedHours(0);
    }

    useEffect(() => {
        setTypeSel(type);
        defaultMin(minAppr);
    }, []);

    const submitHoursUpdate = async (minAppr, comment, type, id) => {
        event.preventDefault();
        if (minAppr == null || type == null) {
            setErrorMessage('Error: Ensure hours and type are set.')
        } else {
            setErrorMessage('');
            setOpenPending(true);
            if (newHoursTable) {
                localStorage.setItem('scrollposnew', window.scrollY);
            }
            const response = await serviceApprovalPageController.updateService(minAppr, comment, type, id);
            setOpenPending(false);
            window.location.reload();
        }
    }

    const setAutoApproveUpdate = (newHoursTable, minReq, comment, typeSel, serviceID) => {
        if (newHoursTable) {
            return (<ApproveServiceButton style={{ display: isCoChair ? 'none' : '' }} onClick={() => submitHoursUpdate(minReq, comment, typeSel, serviceID)}>Approve Requested Service Hours</ApproveServiceButton>);
        }
    }

    return (
        <div>
            <Button onClick={openModal}>{(newHoursTable) ? "More/Approval" : "More/Update"}</Button>
            <Modal open={openPending} onClose={closeModalOverlay}>
                <Box sx={modalStyleOverlay}>
                    <CircularProgress style={waitCircleStyle} />
                </Box>
            </Modal>
            <Modal open={open} onClose={closeModal} aria-labelledby={"modal-title-" + serviceID} aria-describedby={"modal-desc-" + serviceID}>
                <Box sx={modalStyle}>
                    <Typography style={{ fontSize: '24px' }} id={"modal-title-" + serviceID + ((newHoursTable) ? 'new' : 'old')}>{name} - {yearGrad}</Typography>
                    <Typography id={"modal-desc-" + serviceID + ((newHoursTable) ? 'new' : 'old')}>{allBaseTypes[typeSel]}</Typography>
                    <hr />
                    <table style={{ border: '1px solid white', borderCollapse: 'collapse', width: '100%' }}>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Service Date: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{new Date(unixService * 1000).toLocaleDateString()}</Typography></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Submission Date: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{new Date(unixSubmit * 1000).toLocaleDateString()}</Typography></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Description: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{descGen(description)}</Typography></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Location: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{location}</Typography></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Supervisor: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{supName} - {supContact}</Typography></td>
                        </tr>
                    </table>
                    <hr />
                    <Typography><strong>Image:</strong></Typography>
                    {getImage(imgPath)}
                    <hr />
                    <Typography><strong>Service Approved:</strong></Typography>
                    <table style={{ width: '100%' }}>
                        <tr>
                            <td><Typography><strong>Presets:&emsp;&emsp;</strong></Typography></td>
                            <td><Button onClick={presetApproved} style={{ color: 'green' }}>Approved</Button></td>
                            <td><Button onClick={presetRejected} style={{ color: 'red' }}>Rejected</Button></td>
                            <td><Button onClick={presetRejectedLate} style={{ color: 'darkorange' }}>Late</Button></td>
                        </tr>
                    </table>
                    <br />
                    <Typography><strong>Previous Approver: </strong>{(reviewerName == null) ? "N/A" : reviewerName}</Typography>
                    <br />
                    <FormControl style={{ width: '100%' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td><TextField label="Approved Hours" type="number" InputProps={{ inputProps: { min: 0, max: minReq / 60, step: 0.25 } }} value={approvedHours} onChange={(e) => { setApprovedHours(e.target.value); }}>{approvedHours}</TextField></td>
                                <td><Typography><strong>Requested Hours: </strong>{(minReq / 60)}</Typography></td>
                            </tr>
                            <tr>
                                <td colSpan='2'>
                                    <InputLabel id={'service-type-' + serviceID + ((newHoursTable) ? 'new' : 'old')} style={{ marginTop: '4rem' }}>Type of Service</InputLabel>
                                    <Select value={typeSel} onChange={(e) => setTypeSel(e.target.value)} label="Type of Service" labelId={'service-type-' + serviceID + ((newHoursTable) ? 'new' : 'old')}>
                                        {typeOptionList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='2'><TextField style={{ width: '100%' }} label="Comments" value={comment} onChange={(e) => { setComment(e.target.value); }}>{comment}</TextField></td>
                            </tr>
                        </table>
                        <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
                        <UpdateServiceButton style={{ display: isCoChair ? 'none' : '' }} onClick={() => submitHoursUpdate(60 * approvedHours, comment, typeSel, serviceID)}>Update Service Hours</UpdateServiceButton>
                        {setAutoApproveUpdate(newHoursTable, minReq, comment, typeSel, serviceID)}
                    </FormControl>
                </Box>
            </Modal>
        </div>
    );
}

export const ViewToggle = ({ idNewService, idAllService, idStudent }) => {
    const goToOverall = (event) => {
        document.getElementById(idAllService).style.display = "flex";
        document.getElementById(idNewService).style.display = "none";
        document.getElementById(idStudent).style.display = "none";
    };

    const goToNew = (event) => {
        document.getElementById(idAllService).style.display = "none";
        document.getElementById(idNewService).style.display = "flex";
        document.getElementById(idStudent).style.display = "none";
    };

    const goToStudent = (event) => {
        document.getElementById(idAllService).style.display = "none";
        document.getElementById(idNewService).style.display = "none";
        document.getElementById(idStudent).style.display = "flex";
    }

    return (
        <div style={{ maxWidth: 'max-content' }}>
            <Button onClick={goToOverall}>Student Overview</Button>
            <Button onClick={goToNew}>Manage New Service</Button>
            <Button onClick={goToStudent} id="studentIndividualTab" style={{ display: 'none' }}>Individual Student</Button>
        </div>
    );
};


