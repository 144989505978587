import React, {
    ReactElement,
    useEffect,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

import {
    Container,
} from '../resources_page/styles/resources_page.style';
import {
    Innerr,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Heading6,
    BreakLine,
    SummaryText,
    SummaryTextCenter,
    Image,
    ImgCaption,
} from './styles/reunion_pages.style';

export default function ReunionFAQLandingPage() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <PageTemplate active="faq" reunion={true}>
            <HeaderCard bkgSrc="/images/headers/SpringAutumnSidewalk.jpg">
                <HeaderCard.Title>FAQ</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Innerr>
                    <Heading1>Clarkson University Honors Program Alumni Reunion Weekend</Heading1>
                    <BreakLine />
                    <Heading2>June 20-22, 2025</Heading2>
                    <BreakLine />
                    <Image src='/images/reunion/Honors Program 2019_2.jpg' />
                    <ImgCaption>July 2009</ImgCaption>
                    <BreakLine />
                    <Image src='/images/reunion/Honors Group and Team Building 2010.JPG' />
                    <ImgCaption>Honors Group and Team Building - August 2010</ImgCaption>
                    <BreakLine />
                    <Image src='/images/reunion/Honors Grads C2009.JPG' />
                    <ImgCaption>Honors Graduating Class of 2009 - May 2009</ImgCaption>
                    <BreakLine />
                    <Heading2>More details to come!</Heading2>
                    <BreakLine />
                    <SummaryTextCenter><i>Issues with any photos? Feel free to send an email at webmaster@clarksonhonors.org with specific information and requests.</i></SummaryTextCenter>
                </Innerr>
            </Container>
        </PageTemplate>
    );
}